import { mapState } from 'vuex';
export default {
  name: "",

  data() {
    return {
      title: this.$api.api_title,
      article_list: [],
      page_num: 1,
      page_size: 6,
      total: 0,
      total_page: 0,
      loading: false
    };
  },

  computed: { ...mapState({
      device: state => state.device,
      language: state => state.language
    })
  },
  watch: {},

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    // 首页/尾页
    goPage(page) {
      this.page_num = page;
      this.getData();
    },

    // 上一页/下一页
    changePage(type) {
      if (type == '-1' && this.page_num == 1) {
        return false;
      }

      if (type == '+1' && this.page_num == this.total_page) {
        return false;
      }

      this.page_num = type == '-1' ? this.page_num - 1 : this.page_num + 1;
      this.getData();
    },

    // 获取列表数据
    getData() {
      this.loading = true;
      this.$http.get(this.$api.news_whList, {
        params: {
          pageNum: this.page_num,
          pageSize: this.page_size,
          nhLanguage: this.$store.state.language
        }
      }).then(res => {
        if (res.code == 0) {
          // alert(11)
          this.article_list = res.data.rows;
          console.log(this.article_list);
          this.total = res.data.total;
          this.total_page = this.total % this.page_size > 0 ? parseInt(this.total / this.page_size) + 1 : parseInt(this.total / this.page_size);
          console.log('total_page=' + this.total_page);
          this.loading = false;
        }
      });
    },

    changeRoute(row, index) {
      this.$router.push({
        path: '/articleDetail',
        query: {
          last: JSON.stringify(this.select_tab),
          path: '/popularization',
          module: this.module,
          params: JSON.stringify(row),
          page_num: this.page_num,
          page_size: this.page_size,
          index: index
        }
      });
    }

  }
};